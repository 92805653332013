import RejectBlock from 'components/common/reject-block'

export default function NotFound() {
  const t = useI18n()
  return (
    <RejectBlock
      title={ t('error:404.title') }
    >
      <div className='mb-32'>{ t('error:404.description') }</div>
    </RejectBlock>
  )
}
