export default function RejectBlock({ title, children }) {
  return (
    <div className='bg-white'>
      <div className=' container pt-16 font-bold'>
        <div className='text-xl pb-7'>{ title } </div>
        <div>
          { children }
        </div>
      </div>
    </div>
  )
}
